import estadosCidadesJson from '@/assets/js/estados-cidades2.json'

export default {
    estadosCidadesJson,

    estadosDisponiveis: () => {
        // Depois ordenar alfabeticamente
        return estadosCidadesJson.states.sort((state1, state2) => state1.sigla > state2.sigla? 1:-1 );
    },
    cidadesDisponiveis: (id_estado) => {
        const cidadesDisp = estadosCidadesJson.cities.filter( cidade => cidade.state_id == id_estado);
        if(cidadesDisp) {
            return cidadesDisp.map(cidade => ({
                ...cidade, 
                sigla_estado: estadosCidadesJson.states.find(estado => estado.id == cidade.state_id)
            })).sort((cidade1, cidade2) => cidade1.name > cidade2.name? 1:-1);
        }
        return [];
    },
    cidadesDisponiveisSigla: (sigla_estado) => {
        const estado = estadosCidadesJson.states.find(state => state.sigla == sigla_estado)
        if(!estado)  {
            return []
        }
        const id_estado = estado.id
        const cidadesDisp = estadosCidadesJson.cities.filter( cidade => cidade.state_id == id_estado);
        if(cidadesDisp) {
            return cidadesDisp.map(cidade => ({
                ...cidade, 
                sigla_estado: estadosCidadesJson.states.find(estado => estado.id == cidade.state_id)
            })).sort((cidade1, cidade2) => cidade1.name > cidade2.name? 1:-1);
        }
        return [];
    },
    dadosCidade: (cod_cidade) => {
        const cidadeEncontrada = estadosCidadesJson.cities.find( cidade => cidade.id == cod_cidade);
        if(cidadeEncontrada) {
            return {
                ...cidadeEncontrada,
                sigla_estado: estadosCidadesJson.states.find(estado => estado.id == cidadeEncontrada.state_id).sigla,
            }
        }
        return null;
    },
    buscaEndereco: (axios, cep, callback) => {
        if(cep) {
            if(cep.length == 8) {
                axios.get(`https://viacep.com.br/ws/${cep}/json`)
                    .then( response => {
                        if(response) {
                            if(response.data) {
                                if(response.data.erro) {
                                    callback('Busca do CEP retornou um erro', null)
                                    return;
                                } else {
                                    callback(null, response.data);
                                    return;
                                }
                            }
                        }
                        callback('Erro na busca do CEP', null)
                        return;
                    })
                    .catch(err => {
                        console.log('Erro na busca do CEP', err);
                        callback('Erro na requisição de busca do CEP', null);
                        return;
                    });
            } else {
                callback('Cep deve conter 8 caracteres e todos devem ser numéricos.', null);
            }

        } else {
            callback('Cep não informado', null);
        }
        return;
    }
}
